'use strict'

angular.module('cb.postcode-service', [])
	.factory('PostcodeService', ["$http", function ($http) {
		var PostcodeService = {
			/* 			print: function () {
					console.log('postcode works');
				}, */
			getPostcodesAutocomplete: function (partial) {
				try {
					var url = 'https://api.postcodes.io/postcodes/' + partial + '/autocomplete';
					return $http.get(url);
				} catch (error) {
					console.error(error);

				}
			},
			getPostcodeInfo: function (par) {
				try {
					var url = 'https://api.postcodes.io/postcodes/' + par;
					return $http.get(url);
				} catch (error) {
					console.error(error);
				}
			}
		};
		return PostcodeService;
	}]);
